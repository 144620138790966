import type { components } from "../types/scoresaber";

export interface ScoreMessage {
  score: components["schemas"]["Score"];
  leaderboard: components["schemas"]["LeaderboardInfo"];
}

type Command<N, T> = { commandName: N; commandData: T };

const getScoreStream = ({
  users,
  callback,
}: {
  users?: string[];
  callback: (message: ScoreMessage) => void;
}) => {
  const ws = new WebSocket("wss://scoresaber.com/ws");
  ws.onmessage = ({ data }) => {
    if (data === "Connected to the ScoreSaber WSS") return;
    const { commandData } = JSON.parse(data) as Command<"score", ScoreMessage>;
    const setBy = commandData.score.leaderboardPlayerInfo?.id;

    if (!users || (setBy && users.includes(setBy))) callback(commandData);
  };

  return {
    close: () => ws.close(),
  };
};

export default getScoreStream;
