import React, { useEffect, useState } from "react";
import "./App.css";
import getScoreStream, { ScoreMessage } from "./scoreStream";
import Score from "./components/score";

function App() {
  const [scores, setScores] = useState<ScoreMessage[]>([]);
  const [users, setUsers] = useState<string[] | undefined>();
  useEffect(() => {
    if (users === undefined) {
      const url = new URL(window.location.href);
      const config = url.searchParams.get("players") ?? "";
      const users = config?.split(",");
      if (config.length >= 1) setUsers(users);
    }
    const scoreStream = getScoreStream({
      callback: (score) => {
        setScores(
          [score, ...scores].filter(
            (i) => Date.now() - new Date(i.score.timeSet).getTime() < 10000
          )
        );
      },
      users,
    });

    return () => scoreStream.close();
  }, [scores, users]);
  Twitch.ext.configuration.onChanged(() => {
    const config =
      Twitch.ext.configuration.broadcaster?.content ??
      window.location.hash.replace(/^#/, "");
    const users = config?.split(",");
    setUsers((config?.length ?? -1) > 0 ? users ?? undefined : undefined);
  });
  return (
    <div className="App p-8">
      <div className="flex flex-col gap-1">
        {scores.map(({ leaderboard, score }) => (
          <Score
            key={`${leaderboard.id}${score.leaderboardPlayerInfo?.id}${score.timeSet}`}
            score={{ score, leaderboard }}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
